import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import logError from "utils/logError";
// import formatImgixUrl from "utils/image/formatImgixUrl";

type IncomingParams = {
  cbd?: string;
  thc?: string;
  primary_terpene?: string;
  secondary_terpene?: string;
  tertiary_terpene?: string;
  take?: string;
  skip?: string;
  strain_id?: string;
  effect?: string;
  energize_score?: string;
};

const getSimilarStrains = async (
  params: IncomingParams,
  coordinates: Coordinates,
) => {
  const {
    cbd,
    thc,
    primary_terpene,
    secondary_terpene,
    tertiary_terpene,
    effect,
    energize_score,
    take,
    skip,
    strain_id,
  } = params;

  try {
    const strainsResponse = await consumerApi.get(
      `/api/strains/v1/similar_flowers`,
      {
        params: {
          cbd: (cbd && parseInt(cbd, 10)) || undefined,
          effect: effect ? effect.toLowerCase() : undefined,
          energize_score: energize_score || undefined,
          lat: coordinates.lat,
          lon: coordinates.lon,
          primary_terpene: primary_terpene
            ? primary_terpene.toLowerCase()
            : undefined,
          secondary_terpene: secondary_terpene
            ? secondary_terpene.toLowerCase()
            : undefined,
          skip: (skip && parseInt(skip, 10)) || undefined,
          strain_id: strain_id,
          take: (take && parseInt(take, 10)) || undefined,
          tertiary_terpene: tertiary_terpene
            ? tertiary_terpene.toLowerCase()
            : undefined,
          thc: (thc && parseInt(thc, 10)) || undefined,
        },
      },
    );

    // not currently using nug images
    // strainsResponse.data.data.forEach((_strain: any, i: number) => {
    //   const { data } = strainsResponse;
    //   if (data.data[i].nugImage) {
    //     data.data[i].nugImage = formatImgixUrl(data.data[i].full_url);
    //   }
    // });

    return strainsResponse.data.data || {};
  } catch (e) {
    logError(e.message, {
      functionName: "getSimilarStrains",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return;
  }
};

export default getSimilarStrains;
