import React from "react";

const COLUMN_WIDTH = 167;

type Props = {
  columnHeader?: boolean;
  children: string | JSX.Element | JSX.Element[];
};

const ComparisonColumn = ({ columnHeader = false, children }: Props) => {
  return (
    <>
      {columnHeader ? (
        <th
          className="p-sm m-none h-full self-start text-xs text-left font-normal"
          style={{ width: COLUMN_WIDTH }}
          role="columnheader"
          scope="col"
        >
          {children}
        </th>
      ) : (
        <td
          className="p-sm m-none h-full self-start text-xs"
          style={{ minHeight: 36, width: COLUMN_WIDTH }}
        >
          {children}
        </td>
      )}
    </>
  );
};

export default ComparisonColumn;
